.loading {
  position: absolute;
  width: 100%;
  height: 105%;
  min-height: calc(100vh - 100px);
  overflow: hidden;
  background-color: #ffffff;
  z-index: 10;
  opacity: 0.8;
  align-items: center;
  padding-right: 100px;
}

.loading > svg {
  position: fixed;
  width: 100px;
  height: 100px;
}
