/* DropdownMultiSelect.module.css */

.filter_box,
.select_box {
  position: relative;
  min-width: 210px;
  max-width: 300px;
  height: 50px;
  border-radius: 5px;
  background-color: #d8d8d9;
}

.filter_box .trigger_btn,
.select_box .trigger_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 15px;
  width: 100%;
  height: 100%;
  background-color: #d8d8d9;
  border: none;
  border-radius: 5px;
  font-size: 12px;
}

.hover_all_value {
  display: none;
  position: absolute;
  text-wrap: wrap;
  width: fit-content;
  background-color: rgb(82, 82, 82);
  z-index: 1003;
  font-size: 10px;
  padding: 2px;
  border-radius: 5px;
  line-height: 1.3em;
  color: #ffffff;
  top: 42px;
  left: 5px;
  right: 5px;
  align-self: center;
}

.filter_box .label:hover ~ .hover_all_value {
  display: block;
}


.dropdown_content {
  position: absolute;
  top: 0;
  width: 322px;
  height: 500px;
  z-index: 1003;
  font-size: 12px;
  background-color: #d8d8d9;
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3);
}

.dropdown_content > .options_head {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: bold;
  height: 40px;
  padding: 5px 24px 4px;
  color: #ffffff;
  overflow: hidden;
  background-color: #000000;
}

.dropdown_content > .search_bar {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 24px 4px;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown_content > .search_bar > input {
  width: calc(100% - 10px);
  height: 30px;
  background-color: transparent;
  outline: none;
  border: none;
}

.dropdown_content > .options {
  padding: 10px 0;
  list-style: none;
  margin: auto;
  height: calc(100% - 80px);
  overflow: scroll;
}
.dropdown_content > .options::-webkit-scrollbar {
  display: none;
}

.dropdown_content > .options .container {
  display: flex;
  justify-content: space-between;
  padding: 5px 24px 4px;
  align-items: center;
}

.filter_box .dropdown_content > .options .container > div {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.dropdown_content > .options .container > span {
  font-size: 12px;
  margin-bottom: 3px;
}

.options > li {
  padding: 0px;
}

.record_count {
  display: inline-block;
}

.only_btn {
  display: none;
  border: none;
  background-color: rgba(0, 0, 0, 0.1);
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.only_btn:hover {
  text-decoration: underline;
}

.filter_box .options > li:hover .record_count {
  display: none;
}
.filter_box .options > li:hover .only_btn {
  display: block;
}

.filter_box input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  appearance: none;
  background-color: #d8d8d9;
  border: 2px solid white;
  border-radius: 2px;
  cursor: pointer;
}

.filter_box input[type="checkbox"]:checked {
  background-color: white;
}

.filter_box input[type="checkbox"]:checked::before {
  content: "\2713";
  display: block;
  width: 100%;
  height: 100%;
  font-weight: bolder;
  text-align: center;
  color: black;
}

.select_box .dropdown_content > .options .container > div {
  width: 100%;
  display: flex;
  gap: 3px;
  align-items: center;
  cursor: pointer;
}

.select_box input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  padding-top: 3px;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.select_box input[type="checkbox"]:checked::before {
  content: "\2713";
  display: block;
  width: 100%;
  height: 100%;
  font-weight: bolder;
  text-align: center;
  color: black;
}

.select_box .options > li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
