.sidebar {
  display: flex;
  gap: 1px;
  flex-direction: column;
  position: fixed;
  height: calc(100vh - 60px);
  background-color: #2d2e38;
  top: 60px;
  left: 0;
  z-index: 999;
  overflow: hidden;
}

.left_slider > div {
  width: 100%;
}

.sidebar .extender {
  height: 50px;
  color: #ffffff;
  font-size: 20px;
  padding-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  font-family: Tahoma;
  background-color: #2d2e38;
}

.sidebar .extender img {
  width: 55px;
  height: 50px;
}

.sidebar .pagination {
  height: calc(100% - 50px);
  border-bottom: 1px solid #56595f;
  list-style: none;
  margin: 20px 0px 0px;
  overflow: scroll;
}
.sidebar .pagination::-webkit-scrollbar {
  display: none;
}

.sidebar .pagination li {
  margin: 0px auto;
  height: 50px;
  font-size: 20px;
  color: #ffffff;

  /* display: flex;
  align-items: center;
  justify-content: center; */
  font-family: var(
    --nav-font-family,
    "Google Sans",
    "Helvetica Neue",
    sans-serif
  );
}

.page_no {
  height: 45px;
  margin: 5px;
  min-width: 45px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_name {
  font-size: 15px;
  height: 45px;
  padding-left: 20px;
  width: 245px;
  margin: 5px 0;
  border-radius: 0 24px 24px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-start;
}

.page_name_float {
  display: none;
  font-size: 14px;
  background-color: #5f6368;
  padding: 3px 5px;
  border-radius: 5px;
  position: fixed;
  left: 20px;
  margin-top: -5px;
  max-width: 200px;
  letter-spacing: 0.5px;
  text-align: left;
}

.page_name:hover + .page_name_float,
.page_no:hover + .page_name_float {
  display: inline !important;
}

.active {
  color: #4098ff;
  background-color: #334560;
}
