

.App {
  text-align: center;
  width: 100%;
}



/* Table overwrite CSS */
.ant-table-thead .ant-table-cell{
  background-color: #000000 !important;
  color: #ffffff !important;
  border: none !important;
  padding: 5px 1px !important;
}
.dataTable .ant-table-thead .ant-table-cell{
  font-size: 9px !important;
}

.ant-table-cell{
  text-align: center !important;
  font-size: 11px !important;
  font-family: Tahoma, Verdana, Segoe, sans-serif !important;
}


.ant-table-row > .ant-table-cell  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px !important;
}

.ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}


/* Popover overwrite css */
.ant-popover-inner {
  border-radius: 3px !important;
  font-size: 12px;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12) !important;
  text-align: left;
  min-width: 112px;
  max-width: 280px;
}



#legend-container {
  display: flex;
  background-color: red;
}

.custom_popover {
  background-color: red;
}