.header {
  height: 60px;
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  padding: 0px 20px;
  top: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1005;
  border-bottom: 1px solid #d8dde0;
}

.header > div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header img {
  width: 50px;
  margin: auto;
}

.header h2 {
  font-weight: 500;
  margin: auto;
}

.header .account {
  margin: auto;
}

.header .account img {
  width: 40px;
  margin: auto;
  border-radius: 50%;
}

.reset_btn {
  display: flex;
  gap: 10px;
  height: 36px !important;
  padding: 5px 10px;
  align-items: center;
  font-family: Google Sans, Roboto, Arial, sans-serif;
  font-size: 0.875rem;
  letter-spacing: 0.0107142857em;
  font-weight: 500;
  text-transform: none;
  color: #1B73E9;
  border-color: #D8DDE0;
  
}
.reset_btn svg {
  width: 22px;
}

.reset_btn:hover {
    background-color: #e7eef9;
}