.heading {
  position: relative;
  background-color: #000000;
  margin: 10px auto;
  font-family: Tahoma;
  border-radius: 10px;
}
.heading .text {
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  padding: 5px;
  border-radius: 10px;
  text-transform: uppercase;
}

.heading > span {
  color: #ffffff;
  position: absolute;
  right: 10px;
  top: 2px;
  background-color: transparent;
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 900;
}

.heading > span:hover {
  background-color: rgb(71, 71, 71);
}

.pop_up li {
  cursor: pointer;
}
.expandable_tab {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}

.expandable_tab.show {
    max-height: 210px; 
}