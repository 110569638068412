.date_range_container {
  min-width: 210px;
  max-width: 300px;
  position: relative;
}
.date_range_container > button {
  display: flex;
  font-family: Tahoma;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 50px;
  font-weight: normal;
  background-color: #d8d8d9;
  border: none;
  border-radius: 5px;
  transition: all 0.2s;
  color: rgba(0, 0, 0, 0.88);
  font-size: 12px;
  line-height: unset;
  padding: 0px 10px 0px 15px;
}

.date_picker_box {
  font-family: roboto !important;
  background: #fff;
  position: absolute;
  top: 10px;
  z-index: 1003;
  -webkit-box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3);
  box-shadow: 0 12px 12px rgba(0, 0, 0, 0.3);
  font-family: roboto;
}

.date_picker_box p {
  background: #e0e0e0;
  font-size: 13px;
  padding: 8px;
  margin-bottom: 15px;
  text-align: center;
}
.date_picker_box .header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  padding: 5px 10px 0px;
}
.date_picker_box .footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  padding: 0px 10px 10px;
}
.date_picker_box .footer > button {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-size: 13px;
  border: none;
  outline: 0;
  cursor: pointer;
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  border-radius: 2px;
  user-select: none;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    -webkit-box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  letter-spacing: 0.01em;
  white-space: nowrap;
  text-transform: uppercase;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
}
.date_picker_box .footer > button:last-child {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  font-weight: 600;
}

.date_picker_box input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  appearance: none;
  margin: auto;
  border: 2px solid #b9b9bd;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
}

.date_picker_box input[type="checkbox"]:checked {
  background-color: white;
}

.date_picker_box input[type="checkbox"]:checked::before {
  content: "\2713";
  display: block;
  width: 100%;
  height: 100%;
  font-weight: bolder;
  text-align: center;
  color: black;
}

.date_picker_box .shortcuts > select {
  width: 130px;
  height: 30px;
  outline: none;
  border: none;
  border-bottom: 1px solid black;
}

.date_picker_box .next_prev_label {
  width: 10px;
}

.react-calendar__month-view__days__day--weekend abbr {
  color: black;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: gray;
}
.react-calendar__navigation__arrow {
  min-width: 25px !important;
}
.react-calendar__tile,
.react-calendar__month-view__weekdays__weekday {
  width: 25px !important;
  height: 25px !important;
  padding: 2px !important;
  line-height: 25px !important;
}

.react-calendar__tile {
  font-family: Tahoma !important;
}

.react-calendar__tile--now {
  background: #fff !important;
}
.react-calendar__tile--now abbr {
  font-weight: 900;
}

.react-calendar__tile--active {
  background-color: #616060 !important;
  border-radius: 10px;
  padding: 0 !important;
}

.date_picker_box .react-calendar {
  width: 250px !important;
  font-family: Tahoma !important;
  border: none;
  min-height: 215px !important;
  padding: 0px 10px 10px !important;
}
.react-calendar__navigation {
  height: 25px !important;
  margin: 5px !important;
}
.react-calendar__navigation__label {
  border: 1px solid #e0e1e1 !important;
}
.react-calendar__month-view__weekdays abbr {
  text-transform: none !important;
  font-size: 12px !important;
  font-weight: 600 !important;

  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important;
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important;
}

.react-calendar__month-view__weekdays abbr {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.54) !important;
  cursor: default;
}
