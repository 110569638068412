.page_section {
  margin-top: 80px;
  display: flex;
  height: fit-content;
}

.side_shadow {
  min-height: 100%;
  margin-right: 20px;
}

.page {
  margin: auto;
  padding: 20px;
  width: calc(100% - 95px);
  min-width: 1200px;
  max-width: 1313px;
  height: fit-content;
  min-height: calc(100vh - 80px);
  background-color: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26);
}

.table_container {
  margin: 10px 0px;
  position: relative;
  height: 100%;
}

.rc-virtual-list-scrollbar-thumb {
  display: none;
}

.filter_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  margin-bottom: 10px;
  gap: 15px;
  justify-content: space-between;
}

.visualize {
  position: relative;
  height: fit-content;
  min-height: calc(100vh - 180px);
  overflow: hidden;
}

.line_chart {
  position: relative;
  padding-bottom: 10px;
}

.line_chart span {
  position: absolute;
  right: 20px;
  bottom: -10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.chart_container {
  height: 360px;
  margin-bottom: 40px;
}

.chart_container2 {
  height: fit-content;
  margin: 0px auto 20px;
}

.chart_container2 canvas {
  height: 220px !important;
}

.no_data {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f1f1f1;
  display: flex;
}

.no_data>img {
  width: 70px;
  height: 65px;
  border-radius: 10px;
}

.no_data>.loading_gif {
  width: 100px;
  height: 100px;
}

.no_data>p {
  font-size: 1.2rem;
  font-weight: 500;
}

.rc-virtual-list-holder-inner {
  margin-left: 0px !important;
  overflow: hidden !important;
}

.dataTable {
  height: 650px;
  margin-bottom: 115px;
}

.dataTable~span {
  position: absolute;
  bottom: 5px;
  left: 30%;
  right: 30%;
}


.notice_head {
  position: relative;
  margin: 10px 0 0 0;
  padding: 5px 0 10px;
  text-align: center;
  border: 1px solid #8C9CA6;
  border-bottom: none;
}

.notice_head>span {
  position: absolute;
  right: 13px;
  bottom: 13px;
  font-size: 16px;
  display: block;
  font-weight: 900;
  cursor: pointer;
  border-radius: 50%;
  padding: 0 3px;
  background-color: #ffffff;
}

.notice_head>span:hover {
  background-color: #babdc0;
}

.notice {
  border: 1px solid #8C9CA6;
  border-top: none;
  text-align: left;
  padding: 0px 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.notice.show {
  max-height: 576px;
}

.notice>div {
  padding: 10px 20px;
  margin-bottom: 20px;
  /* box-shadow:  rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; */
  box-shadow: inset rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.notice>div:nth-of-type(1) li {
  list-style: none;
  margin-bottom: 4px;
}

.notice span {
  font-size: small;
  padding: 0px 6px;
  border-radius: 5px;
  padding-bottom: 2px;
}


.notice>div:nth-of-type(1) span {
  background-color: #263238;
  color: #ffffff;
}

.notice>div:nth-of-type(2) span {
  background-color: #ffa800;
}

.notice .tag {
  background-color: #8C9CA6 !important;
  color: #000000 !important;
}