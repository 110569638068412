.customLegend {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 40px;
  overflow: scroll;
  overflow: -moz-scrollbars-horizontal;
  margin: -15px 50px 0px 50px;
  scrollbar-width: 1px;
}

.customLegend > li {
  display: flex;
  gap: 3px;
  align-items: center;
  height: 23px;
  width: fit-content;
  text-wrap: nowrap;
}

.customLegend > li > span:first-child {
  display: flex;
  gap: 3px;
  align-items: center;
  font-size: 14px;
  height: 15px;
  cursor: pointer;
}

.customLegend > li > span > span {
  width: 20px;
  height: 7px;
}

.customLegend > li > span:last-child {
  min-width: 15px;
  height: 13px;
}

.customLegend > li img {
  display: none;
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.customLegend > li:hover img {
  display: block;
}

.customLegendTable {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 40px;
  overflow: scroll;
  background-color: #ffffff;
  border-radius: 5px;
  justify-content: center;
  margin-bottom: 10px;
}

.customLegendTable > li {
  display: flex;
  gap: 3px;
  align-items: center;
  height: 23px;
  width: fit-content;
  text-wrap: nowrap;
}

.customLegendTable > li > span:first-child {
  display: flex;
  gap: 3px;
  align-items: center;
  font-size: 14px;
  height: 15px;
}

.customLegendTable > li > span > span {
  width: 25px;
  margin-top: 2px;
  height: 10px;
}

.customLegendTable > li > span:last-child {
  min-width: 15px;
  height: 13px;
}

.toggle_btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.toggle_btn {
  margin: 0px 15px;
}
.toggle_btn > p {
  margin: 0px;
}

.my-data-table .rc-virtual-list-holder > div {
  height: 520px !important;
}

.my-data-table .ant-table-cell {
  border-bottom: 1px solid black !important;
  border-inline-end: 1px solid black !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.my-data-table .rc-virtual-list-holder-inner,
.half_table .rc-virtual-list-holder-inner {
  border-inline-start: 1px solid black !important;
  border-inline-end: 1px solid black !important;
}

.my-data-table .ant-table-thead .ant-table-cell {
  font-size: 11px !important;
}

/* myDynamicTable */

.myDynamicTable {
  font-size: 0.5rem;
  width: 100%;
  border-collapse: collapse;
}

.myDynamicTable th,
.myDynamicTable td {
  text-align: center;
  padding: 6px;
}

.myDynamicTable thead {
  background-color: #000000;
}

.myDynamicTable th {
  background-color: #000000;
  position: sticky;
  top: 0;
  height: 52px;
}

.keyGroupTab {
  margin: 8px;
  display: flex;
  gap: 10px;
  overflow: scroll;
}

.bubble {
  border: 1px solid #c0c0c0;
  cursor: pointer;
  padding: 3px 10px;
  border-radius: 8px;
  font-size: 12px;
  text-wrap: nowrap;
}

.customLegend::-webkit-scrollbar,
.keyGroupTab::-webkit-scrollbar {
  -webkit-appearance: none;
}

.customLegend::-webkit-scrollbar:horizontal,
.keyGroupTab::-webkit-scrollbar:horizontal {
  height: 7px;
}
.customLegend::-webkit-scrollbar:vertical,
.keyGroupTab::-webkit-scrollbar:vertical {
  display: none;
}

.customLegend::-webkit-scrollbar-thumb,
.keyGroupTab::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.5);
}

.customLegend::-webkit-scrollbar-track,
.keyGroupTab::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.keyGroupTab, .myDynamicTable thead {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}