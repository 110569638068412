.auth_tab {
  width: fit-content;
  min-width: 100%;
  height: fit-content;
  min-height: 100vh;
  max-width: 1313px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0px;
  align-items: center;
  box-sizing: border-box;
}

.auth_tab > div {
  height: 500px;
}

.gif {
  position: relative;
  min-width: 390px;
}
.gif > img {
  position: absolute;
}
.gif > img:first-child {
  width: 92%;
  right: 0;
  top: 50px;
}
.gif > img:nth-child(2) {
  width: 38%;
  top: 163px;
  right: 55px;
}
.gif > img:last-child {
  width: 38%;
  top: 45px;
  right: 230px;
  animation: shake 3s ease infinite;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.error_display {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
}
.error_display > h1 {
  font-size: 65px;
  text-align: center;
  margin: 0px;
}
.error_display > h1 > span {
  font-weight: 300;
  font-size: 70px;
}
.error_display > span:first-child {
  align-self: center;
  font-weight: 600;
  font-size: 30px;
}
.error_display > strong {
  font-weight: 300;
  font-size: 30px;
}

.error_display > div {
  margin-bottom: 50px;
}

.error_display > div > p {
  color: gray;
  font-size: 1.3rem;
}

.button {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

.ant_btn {
  padding: 5px;
  text-transform: capitalize;
  text-wrap: wrap;
  position: absolute;
  top: 10px;
  left: 20px;
  background: transparent;
  border: 1px solid black;
}

.login_page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
}

.login_page > .display {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  padding: 10px;
}

.login_page > .display > div {
  display: flex;
  gap: 20px;
}

.login_page > .display > div:last-child {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 100px);
}

.login_page > .display > div:first-child > h1 {
  font-size: 20px;
  text-align: center;
  margin: 0px;
}

.login_page > .display > div:first-child > img {
  width: 60px;
}

.login_page > .display > div:first-child > h1 > span {
  font-weight: 300;
  font-size: 2.2rem;
}
.login_page > .display > div:last-child > h1 {
  font-size: 1.7rem;
}

@media (min-width: 450px) {
  .button {
    padding: 0.25em 0.75em;
    height: 50px;
    width: 250px;
  }
  .auth_tab {
    flex-direction: row;
    gap: 150px;
  }
  .error_display > strong {
    font-size: 35px;
  }

  .login_page > .display > div:first-child > h1 {
    font-size: 55px;
  }

  .login_page > .display > div > h1 > span {
    font-size: 3rem;
  }

  .login_page > .display > div:first-child > img {
    width: 100px;
  }

  .login_page > .display > div:last-child > h1 {
    font-size: 2.5rem;
  }
}
